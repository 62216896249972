<template>
  <div>
    <!-- 引导页管理 -->
    <!-- <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-input v-model="queryParams.guideName" clearable placeholder="请输入引导页名称" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.guideManager" clearable placeholder="请输入引导页负责人" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
      </el-form-item>
    </el-form> -->
    
    <div class="content-wrap">
      <div class="btn-wrap cfx">
        <el-button v-if="OA.includes('jcxx:ydygl:add')" type="success" size="small" icon="el-icon-plus" @click="handleForm(false)">添加</el-button>
      </div>
      <div v-if="guideTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <!--列表-->
      <paged-table :data="guideList" :total="guideTotal" :loading="tLoading" v-if="guideTotal>0"
        :isShowSelection="false" defaultSortProp="id" v-model="queryParams"
        @refresh="refresh">
        <el-table-column sortable="custom" prop="title" show-overflow-tooltip label="标题"></el-table-column>
        <el-table-column sortable="custom" prop="photo" show-overflow-tooltip label="图片地址">
          <!-- <template slot-scope="scope">
            <div><el-image :src="scope.row.photo"></el-image></div>
          </template> -->
        </el-table-column>
        <el-table-column sortable="custom" prop="sort" show-overflow-tooltip label="排序"></el-table-column>
        <el-table-column sortable="custom" prop="addTime" show-overflow-tooltip label="创建时间"></el-table-column>
        <el-table-column sortable="custom" prop="extend.addUserId" show-overflow-tooltip label="创建者"></el-table-column>
        <el-table-column align="right" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button v-if="OA.includes('jcxx:ydygl:load')" size="mini" @click="handleDetails(scope.row)" icon="el-icon-search" title="详情"></el-button>
            <el-button v-if="OA.includes('jcxx:ydygl:editor')" size="mini" type="primary" @click="handleForm(true, scope.row)" icon="el-icon-edit" title="编辑"></el-button>
            <el-button v-if="OA.includes('jcxx:ydygl:del')" size="mini" type="danger" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除"></el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑引导页' : '添加引导页'" :visible.sync="aVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="120px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="标题：" prop="title">
          <el-input placeholder="请输入标题" style="width:316px;" v-model="form.title" clearable></el-input>
        </el-form-item>
        <el-form-item label="序号：" prop="sort">
          <el-input placeholder="请输入序号" style="width:316px;" v-model="form.sort" clearable :maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="上传引导图片：" prop="photo">
          <base-upload v-model="form.photo" fileType="single" @beforeUpload="handleBeforeUpload"></base-upload>
          <div class="tips">图片格式只支持：JPG、PNG</div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>

    <!-- 详情界面 -->
    <el-dialog title="引导页详情" :visible.sync="bVisible" width="550px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="detailsFileds" :model="details" size="small">
        <el-form-item label="标题：" prop="title">
          <span v-text="details.title"></span>
        </el-form-item>
        <el-form-item label="序号：" prop="sort">
          <span v-text="details.sort"></span>
        </el-form-item>
        <el-form-item label="创建时间：" prop="addTime">
          <span v-text="details.addTime"></span>
        </el-form-item>
        <el-form-item label="创建人：" prop="extend.addUserId">
          <span v-text="details.extend.addUserId"></span>
        </el-form-item>
        <el-form-item label="引导图片：" prop="photo">
          <el-image style="width: 100px; height: 100px" :src="details.photo"></el-image>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import BaseUpload from "@/components/common/BaseUpload";
import datePicker from "@/mixins/datePicker";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'guideManage',
  components: {
    PagedTable,
    BaseUpload
  },
  mixins: [pageMixin, datePicker],
  data() {
    return {
      queryParams: {
        guideName: null,
        guideManager: null,
      },

      form:{
        id:'',
        title:'',
        photo:'',
        sort:'',
      },
      details:{
        title:'',
        photo:'',
        sort:'',
        addTime: '',
        extend: {},
      },
      formRules:{
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        photo: [{ required: true, message: '请上传引导图片', trigger: 'blur' }],
        sort: [{ required: true, message: '请输入序号', trigger: 'blur' }],
      },

      pageApi:'systemGuideList',
      insertApi:'systemGuideAdd',
      editorApi:'systemGuideEdit',
      deleteApi:'systemGuideDelete',
      loadApi:'systemGuideLoad',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("systemGuide", ["guideList", "guideTotal"]),
  },
  methods: {
    ...mapActions("systemGuide",["systemGuideList", "systemGuideAdd", "systemGuideDelete", "systemGuideEdit", "systemGuideLoad"]),
    handleBeforeUpload(file, callback){
		  const isPicture = file.name.includes('jpg') || file.name.includes('png')
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isPicture) {
        this.$message.error('上传引导图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传引导图片大小不能超过 1MB!');
      }
      callback(isPicture && isLt2M)
    },
  },
  async mounted() {
    if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
  }
};
</script>